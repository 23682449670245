.propertyCardMain {
  display: inline-block;
  margin: auto;
  margin: 16px;
  width: 250px;
  height: auto;
  /* background: #F1EDE9; */
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 6px 2px rgba(241, 237, 233, 0.1);
  border-radius: 15px;
  /* backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); */
}

.propertyCardMainImage {
  width: 100%;
  height: 150px;
  border-radius: 15px 15px 0px 0px;
}

.propertyCardName {
  color: black;
  font-weight: 500;
  font-size: 19px; /* Maybe change to 20px */
  line-height: 32px;
  margin: 8px auto auto 16px;
  text-align: left;
}

.propertyCardPrice {
  color: black;
  opacity: 75%;
  text-align: left;
  margin: 0px auto auto 16px;
}

.propertyCardDetails {
  color: black;
  text-align: left;
  margin: 0px auto auto 16px;
}

.propertyCardBottomMain {
  display: flex;
  justify-content: space-between;
}

.propertyCardBottomData {
  color: black;
  font-size: 16px;
}

.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.propertyCardBottomData.css-wk2qm8-MuiButtonBase-root-MuiButton-root {
  margin: 0;
  color: #6868ac !important;
  font-size: 16px;
  display: inline-block !important;
  text-transform: none;
  align-self: center;
}

.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.propertyCardBottomData.css-1dzl6kp {
  margin: 0;
  color: #6868ac !important;
  font-size: 16px;
  display: inline-block !important;
  text-transform: none;
  align-self: center;
}

.deletePropertyWarning {
  margin: 32px;
  font-weight: 400;
  font-size: 20px;
}

@media only screen and (max-width: 960px) {
  .propertyCardMain {
    margin: auto;
    margin-bottom: 40px;
  }

  .propertyCardPrice {
    opacity: 40%;
  }
}

@media only screen and (max-width: 600px) {
  .propertyCardMain {
    width: 80vw;
  }

  .propertyCardName {
    font-size: 24px;
  }

  .propertyCardDetails {
    font-size: 18px;
  }

  .propertyCardBottomData {
    font-size: 18px;
  }

  .propertyCardMainImage {
    height: auto;
  }
}
