.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 16px;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 1;
}

.header-element {
  text-align: center;
  display: inline-block;
  margin-left: 80px;
  margin-right: 80px;
  font-size: 18px;
}

.mobile-header {
  padding-top: 16px;
  text-align: left;
  display: block;
}

.mobile-header-element {
  text-align: left;
  justify-content: start;
  margin-top: 10px;
  margin-left: 16px;
  margin-right: 70px;
  font-size: 20px;
  color: #f1ede9;
}

.navbarNameTab {
  background-image: linear-gradient(90deg, #dbd3cf, #f1ede9) !important;
  background-size: 0% 2px !important;
  background-repeat: no-repeat !important;
  background-position: left bottom !important;
  transition: background-size 300ms ease !important;
}

.navbarNameTab:hover {
  background-size: 100% 2px !important;
}

.Cormorant {
  font-family: "Cormorant Garamond", serif;
  color: #f1ede9;
}

.Jost {
  font-family: "Jost", sans-serif;
  color: #f1ede9;
}

html,
body {
  font-family: "Jost", sans-serif;
  color: #f1ede9;
  background-color: #29282d;
}

body {
  margin: 0;
}

.App {
  text-align: center;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  /* margin-top: -80px; */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.miamiVideo {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: inherit;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: -80px;
  z-index: -5;
}

.container {
  margin: 80px auto 0 auto;
}

.featuredHeader {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 0px;
}

.headerWhybuy {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 0px;
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  /* margin-top: -20px; */
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  min-width: 600px;
  max-width: 50%;
  text-align: center;
}

.WhyBuyImg {
  mask-image: linear-gradient(
    180deg,
    rgba(196, 196, 196, 0) 0%,
    rgba(196, 196, 196, 0.5) 13.3%,
    #c4c4c4 21.61%,
    rgba(196, 196, 196, 0.75) 83.35%,
    rgba(196, 196, 196, 0) 100%
  );
  mask-mode: alpha;
  background-position: center;
  height: 105vh;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 40px;
}

.teamHeader {
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 16px;
  margin-top: 40px;
}

.contactUsPageHeader {
  display: none;
  font-size: 48px;
  font-weight: 300;
  margin-bottom: 16px;
  margin-top: 40px;
}

.TeamMemberImage {
  max-width: 20vw;
  min-width: 230px;
}

.TeamMemberImageDialog {
  min-height: 280px;
  max-height: 50vh;
  margin-top: 40px;
}

.TeamMemberName {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  margin-top: 16px;
}

.TeamMemberNameDialog {
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  margin-top: 16px;
}

.TeamMemberStatus {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  opacity: 60%;
  /* margin-top: 8px; */
}

.TeamMemberStatusDialog {
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  opacity: 60%;
  /* margin-top: 8px; */
}

.TeamMemberNumber {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  margin-top: 10px;
}

.TeamMemberEmail {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  margin-top: 4px;
}

.TeamMemberNumberDialog {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  margin-top: 13px;
  /* color: black; */
}

a {
  text-decoration-color: #6868ac;
  color: #f1ede9;
}

.TeamMemberEmailDialog {
  font-size: 15px;
  font-weight: 300;
  margin: 0;
  margin-top: 10px;
}

/*.TeamDialog {
   border-radius: 30px;
} */

.PersonDialog {
  margin: auto;
  text-align: center;
  color: #f1ede9;
  overflow-x: hidden;
  overflow-y: hidden;
}

.TeamMemberQualityDialog {
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  margin-top: 10px;
}

/* ::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #000;
  opacity: 50%;
}

::-webkit-scrollbar-thumb {
  background: rgba(104, 104, 172, 0.75);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
} */

::-webkit-scrollbar {
  width: 9px;
  /* display: none; */
}

::-webkit-scrollbar-thumb {
  background: rgba(104, 104, 172, 0.75);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:window-inactive {
  display: none;
}

.ContactUsBookMeetingText {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: -50px;
}

.stepsHeaderTextStyle {
  display: flex;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
  margin-left: 40px;
}

.stepsParagraphTextStyle {
  text-align: left;
  justify-content: start;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  margin-top: 8px;
  margin-left: 40px;
  opacity: 90%;
}

.termsParagraph {
  text-align: left;
  max-width: 80vw;
  font-weight: 400;
  line-height: 1.5rem;
  margin: auto;
}

@media only screen and (min-width: 1900px) {
  .teamHeader {
    font-size: 48px;
    font-weight: 300;
    margin-bottom: 16px;
    margin-top: 200px;
  }

  .WhyBuyImg {
    mask-image: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 0%,
      rgba(196, 196, 196, 0.5) 13.3%,
      #c4c4c4 21.61%,
      rgba(196, 196, 196, 0.75) 83.35%,
      rgba(196, 196, 196, 0) 100%
    );
    mask-mode: alpha;
    background-position: center;
    height: 105vh;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 40px;
  }

  .stepsHeaderTextStyle {
    display: flex;
    font-weight: 500;
    font-size: 32px;
    margin: 0;
    margin-left: 40px;
  }

  .stepsParagraphTextStyle {
    text-align: left;
    justify-content: start;
    font-weight: 400;
    font-size: 22px;
    margin: 0;
    margin-top: 8px;
    margin-left: 40px;
    opacity: 90%;
  }
}

@media only screen and (max-width: 898px) {
  .ContactUsBookMeetingText {
    margin-bottom: 16px;
    color: rgba(104, 104, 172, 0.75);
  }

  .headerWhybuy {
    /* font-size: 24px; */
    font-size: 6vw;
    min-width: 300px;
    max-width: 80%;
    margin-top: 0px;
  }

  .teamHeader {
    font-size: 8vw;
    min-width: 300px;
    max-width: 80%;
    margin: auto;
    margin-top: 40px;
  }

  .contactUsPageHeader {
    display: block;
    font-size: 10vw;
    min-width: 300px;
    max-width: 80%;
    margin: auto;
    margin-top: -40px;
    margin-bottom: 0px;
  }

  .TeamMemberNumberDialog {
    font-size: 16px;
    margin-top: 8px;
  }

  .TeamMemberEmailDialog {
    font-size: 16px;
    margin-top: 8px;
  }

  .PersonDialog {
    overflow-y: auto;
  }

  .TeamMemberNameDialog {
    font-size: 24px;
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .styleForCountNumberDiv {
      margin-top: 0px;
    }

    @media only screen and (max-width: 500px) {
      .styleForCountNumberDiv {
        margin-top: 28px;
      }
    }
  }
}

/* 


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
