.mapMainImageProperty {
    width: 100%;
    display: block;
    margin: auto;
    margin: 20px 0 20px 0;
}

.mapViewOnMapBtn {
    color: black;
    font-size: 16px;
}

.mapPropertyName {
    font-size: 20px;
}