.mainImageProperty {
  /* width: 100%; */
  height: 100vh;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.estatePropertyType {
  margin: 0;
  padding-top: 40vh;
  font-weight: 300;
  font-size: 96px;
}

.estatePropertyStreet {
  margin: 0;
  font-weight: 300;
  font-size: 48px;
  margin-left: 30vw;
  color: #29282d;
}

.estatePropertyQuality {
  font-size: 28px;
  /* font-weight: 300; */
  font-weight: 500;
  margin: 0;
  margin-top: 40px;
  color: rgba(104, 104, 172, 1);
}

.estatePropertyQualityName {
  font-weight: 400;
  margin: 0;
  opacity: 80%;
}

.estateHorizontalLine {
  width: 100%;
  height: 0.5px;
  background-color: #dbd3cf;
  margin-top: 24px;
}

.estateHorizontalLineMobile {
  display: none;
}

.estateDetailFeaturesGrid {
  text-align: left;
  padding: 0;
}

.estatesDescription {
  line-height: 28px;
  text-align: left;
  width: 100%;
  margin-top: 40px;
}

.estatesFloorPlan {
  margin-top: 45px;
  width: 75%;
  border-radius: 5px;
  /* height: 100%; */
}

.googleMapStyle {
  border-radius: 5px;
  width: 75%;
}

.propertyQualityBackground {
  background: none;
}

.ImageSliderTopPic {
  width: 95%;
  border-radius: 5px;
  /* height: 100%; */
}

.ImageSliderNumberRemain {
  margin: 0;
  margin: auto;
  /* margin-top: 40%; */
  text-align: center;
  color: rgba(241, 237, 233, 0.5);
  font-size: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.swiper-button-next,
.swiper-button-prev,
.swiper-rtl {
  margin-top: -10% !important;
}

.ProfitCalcMainDiv {
  margin: auto;
  margin-top: 24px;
  height: 150px;
  width: 100%;
  background-color: rgba(241, 237, 233, 0.25);
  border-radius: 10px;
}

.BlurForNonLoggedInOnProfit {
  height: 150px;
  width: 100%;
  /* background-color: #29282d; */
  position: absolute;
  backdrop-filter: blur(10px);
  z-index: 10000;
}

.loginToSeeProfit {
  margin: 0px;
}

.ProfitCalcHeader {
  font-weight: 400;
  font-size: 20px;
  margin: 0;
  padding-top: 16px;
}

.propertyFullPrice {
  margin: 0;
  margin-top: 24px;
  font-size: 32px;
  font-weight: 400;
  margin: auto;
  color: rgba(104, 104, 172, 1);
  font-weight: 600;
}

/* .propertyLowerButton {
    color: #F1EDE9 !important;
    background: red !important;
}

.propertyLowerButton:active {
    color: blue !important;
} */

.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.propertyLowerButton.css-wk2qm8-MuiButtonBase-root-MuiButton-root {
  color: #f1ede9;
  background-color: rgba(104, 104, 172, 0.5);
  border: solid 0.5px rgba(255, 255, 255, 0.1);
}

.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.propertyLowerButton.css-wk2qm8-MuiButtonBase-root-MuiButton-root:hover {
  color: #f1ede9;
  background-color: rgba(104, 104, 172, 0.9);
  border: solid 0.5px rgba(255, 255, 255, 0.2);
}

.propertyBottomLineDevider {
  display: none;
}

.propertyContactUsPhoneNum {
  color: black;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: min(4vw, 20px);
}

.listDetails {
  width: 100%;
}

@media only screen and (max-width: 960px) {
  .listDetails {
    width: 90%;
  }
  .propertyBottomLineDevider {
    display: block;
    height: 0.5px;
    width: 100%;
    background-color: white;
  }

  .ProfitCalcMainDiv {
    height: 150px;
    width: 90%;
    background-color: rgba(241, 237, 233, 0.25);
  }

  .estatePropertyStreet {
    font-size: 32px;
    margin: 0;
  }

  .estateHorizontalLine {
    display: none;
  }

  .estateHorizontalLineMobile {
    display: block;
    width: 100%;
    height: 0.5px;
    background-color: #dbd3cf;
    margin-top: 24px;
  }

  .estateDetailFeaturesGrid {
    text-align: left;
  }

  .estatesDescription {
    width: 80%;
    margin: auto;
    margin-top: 40px;
  }

  .estatesFloorPlan {
    width: 90%;
  }

  .googleMapStyle {
    margin-top: 24px;
    width: 90%;
  }

  .propertyQualityBackground {
    background: linear-gradient(
      180.03deg,
      rgba(170, 170, 170, 0.25) 0.02%,
      rgba(217, 217, 217, 0.0775) 99.98%
    );
    box-shadow: 0px 0px 6px 2px #37373b;
    backdrop-filter: blur(5px);
    border-radius: 15px;
    width: 80%;
    margin: auto;
    margin-top: 40px;
    padding-bottom: 40px;
  }

  .estatePropertyType {
    font-size: 20vw;
  }
}
