.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: white !important;
}

.addPropertyFields {
    width: 60%;
    margin: auto;
}

.css-1pnmrwp-MuiTypography-root {
    color: rgba(256, 256, 256, .5) !important;
}

.addMainImageHeader {
    font-size: 3vw;
    font-weight: 400;
    margin-bottom: 16px;
}

.addAllImagesHeader {
    font-size: 3vw;
    font-weight: 400;
    margin-bottom: 16px;
}

.mainImagePreview {
    width: auto;
    height: 50vh;
}

.mapImagePreview {
    width: auto;
    height: 50vh;
}

.floorPlanImagePreview {
    width: auto;
    height: 50vh;
}

.imagesPreview {
    width: 40%;
    height: auto;
    margin: 16px;
}

/* .MuiButtonBase-root.MuiButton-root.MuiButton-text.submitButton {
    padding: 16px 32px 16px 32px; 
    border: solid 1px rgba(256, 256, 256, 0.1)
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.submitButton:hover {
    background-color: rgba(256,256,256,.05);
    border: solid 1px rgba(256, 256, 256, 0.3)
} */

.submitButton {
    display: block;
    margin: 16px auto 0px auto;
    font-size: 22px;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    background-image: linear-gradient(45deg, rgba(99, 143, 254, 0.7), rgba(16, 150, 99, 0.7));
    border-radius: 7px;
    padding: 8px 16px 8px 16px;
    border: 1px solid rgba(256, 256, 256, 0.2);
    color: rgba(255, 255, 255, 0.9);
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    /* -webkit-transition: background-image 2s ease-out;
    -moz-transition: background-image 2s ease-out;
    -o-transition: background-image 2s ease-out;
    transition: background 2s ease-out; */
    
}

.submitButton:hover {
    background-color: rgba(99, 143, 254, 1);
    transform: scale(1.1);
    border: 1px solid rgba(256, 256, 256, 0.4);
    cursor: pointer;
    -webkit-transition-duration: 0.1s;
    -moz-transition-duration: 0.1s;
    -o-transition-duration: 0.1s;
    transition-duration: 0.1s;
    color: rgba(255, 255, 255, 1);
}

.submitButton:active {
    background-color: rgba(16, 150, 99, 0.7);
    border: 1px solid rgba(256, 256, 256, 0.5);
}




@media only screen and (max-width: 898px) {
    .addPropertyFields {
        width: 80%;
    }

    .addMainImageHeader {
        font-size: 8vw;
        font-weight: 400;
        margin-bottom: 16px;
    }

    .addAllImagesHeader {
        font-size: 8vw;
        font-weight: 400;
        margin-bottom: 16px;
    }
    
    .mainImagePreview {
        width: 80vw;
        height: auto;
    }

    .mapImagePreview {
        width: 80vw;
        height: auto;
    }
    
    .floorPlanImagePreview {
        width: 80vw;
        height: auto;
    }
    
    .imagesPreview {
        width: 100%;
        height: auto;
        margin: 8px 0px 8px 0px;
    }

}