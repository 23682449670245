.TestimonialsHeader {
    font-size: 48px;
    font-weight: 300;
    margin-bottom: 0px;
}

.MiamiTestimonialsImg {
    border-radius: 15px;
    max-width: 415px;
}

.PortraitTestimonialsImg {
    border-radius: 15px;
    max-width: 100px;
    max-height: 100px;
    margin-left: 20px;
    margin-top: 20px;
}

.PersonNameTestimonials {
    display: flex;
    color: rgba(241, 237, 233, 0.9);
    font-size: 32px;
    font-weight: 300;
    margin: 0;
    margin-top: 45px;
}

.PersonCommentTestimonials {
    margin-top: 24px;
    font-weight: 300;
    font-size: 18px;
    width: 375px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    line-height: 30px;
}

.TestimonialsPerson {
    width: 415px;
    height: 100%;
    background: rgba(98, 92, 96, 0.5);
    box-shadow: 2px 2px 6px 2px rgba(98, 92, 96, 0.1);
    border-radius: 15px;
}

.MiamiTestimonialsSmallImg {
    margin: 0;
    border-radius: 15px;
    max-width: 203px;
}

@media only screen and (max-width: 960px) {

    .MiamiTestimonialsImg{
        display: none !important;
    }

    .PortraitTestimonialsImg {
        border-radius: 15px;
        max-width: 150px;
        max-height: 150px;
        margin-left: 20px;
        margin-top: 20px;
    }

    .TestimonialsPerson {
        width: 90vw;
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
    }

    .PersonNameTestimonials {
        font-size: 36px;
        margin-left: 20px;
        margin-top: 70px;
        margin-bottom: auto;
    }

    .PersonCommentTestimonials {
        margin-top: 24px;
        font-weight: 300;
        font-size: 18px;
        width: 80vw;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    .MiamiTestimonialsSmallImg {
        margin-left: auto;
        margin-right: auto;
        /* max-width: auto; */
        max-width: 40vw;
    }

}


@media only screen and (max-width: 436px) {

    .MiamiTestimonialsImg{
        display: none !important;
    }

    .PortraitTestimonialsImg {
        border-radius: 15px;
        max-width: 100px;
        max-height: 100px;
        margin-left: 20px;
        margin-top: 20px;
    }

    .TestimonialsPerson {
        width: 90vw;
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    .PersonNameTestimonials {
        font-size: 22px;
        margin-left: 20px;
        margin-top: 50px;
    }

    .PersonCommentTestimonials {
        margin-top: 24px;
        font-weight: 300;
        font-size: 18px;
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
    }

    .MiamiTestimonialsSmallImg {
        max-width: 45vw;
    }

}











