.footerMain {
        margin-top: 80px;
        background: black;
        width: 100%;
        height: 100%;
        /* display: flex; */
}

.footerLogo {
    width: 150px;
    margin: auto;
}

.footerRights {
    margin: 20px 0px 0px 0px;
    opacity: 75%;
}

.footerMade {
    margin: 32px 0px 0px 0px;
    opacity: 90%;
}

.footerList {
    text-align: left;
}

.footerSpacing {
    height: 16px;
}

@media only screen and (max-width: 960px) {
    .footerRights {
        font-size: 12px;
    }

    .footerList {
        text-align: center !important;
    }
}