.ContactUsMainDiv {
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 25px;
    width: 80vw;
    height: max(600px);
    margin: auto;
    margin-top: -18px;
}

/* .smallHouseImage {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
    width: 45vw;
    border-radius: 0px 0px 25px 0px;
} */

.smallHouseImage {
    /* position: absolute; */
    display: flex;
    margin-right: 0;
    margin-left: auto;
    /* width: 100%; */
    height: max(600px);
    border-radius: 0px 0px 25px 0px;
}

.contactUsHeader {
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 0px;
    margin-top: 40px;
}

.contactUsMiniHeader {
    margin-top: 0px;
    font-size: 14px;
    max-width: 350px;
    margin: auto;
    font-weight: 200;
    opacity: 75%;
}

.contactUsLowerText {
    margin-top: 0px;
    font-size: 10px;
    max-width: 350px;
    margin: auto;
    font-weight: 200;
    opacity: 50%;
}

.ContactTextField{
    /* width: 400px; */
    width: 60%;
}

.DoneButton {
    display: block;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.DoneButton.MuiButton-containedPrimary {
    color: #F1EDE9;
    background-color: #6868AC;
    display: block;
    margin: auto;
    margin-top: 16px;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.DoneButton.MuiButton-containedPrimary:hover {
    background: #484876;
}

.contactUsButtonSubmitText {
    /* margin: 0; */
    /* padding: 0; */
    font-size: 18px;
}

.contactUsHelperText {
    margin-top: 16px;

}

@media only screen and (max-width: 1400px) {

    .smallHouseImage {
        width: 100%
    }

}

@media only screen and (max-width: 898px) {

.contactUsHeader {
    font-size: 8vw;
    min-width: 300px;
    max-width: 80%;
    margin: auto;
    margin-top: 40px;
}

.smallHouseImage {
    display: none;
}

.ContactTextField {
    width: 80%;
}

}

@media only screen and (max-width: 500px) {
    .ContactUsMainDiv {
        width: 90vw;
    }

    .contactUsHeader {
        font-size: 10vw;
    }

    .contactUsMiniHeader {
        max-width: 80%;
    }

    .contactUsLowerText {
        max-width: 80%;
    }

    .contactUsHelperText { 
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }


}