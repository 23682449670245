.mainSocialMediaBox {
  /* height: 80vh; */
  height: 600px;
  width: 80vw;
  background: rgba(0, 0, 0, 0.5);
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 25px;
}

.imageScroller::-webkit-scrollbar {
  width: 5px;
}

.facebookPostMain {
  background: #000000;
  width: 100%;
  height: 105px;
  border-radius: 15px;
  color: #f1ede9;
  text-align: left;
}

.AlexLeraCircle {
  width: 71px;
  /* width: 100%; */
  margin-top: auto;
  margin-bottom: auto;
  margin-top: 16px;
}

.FacebookTopic {
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  margin-top: 24px;
}

.FacebookTitle {
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  margin-top: 24px;
  opacity: 90%;
}

.FacebookText {
  font-weight: 300;
  font-size: 14px;
  margin: 0;
  margin-top: 20px;
  opacity: 75%;
}

@media only screen and (max-width: 960px) {
  .mainSocialMediaBox {
    background-color: transparent;
    height: 1700px;
  }

  .facebookPostMain {
    height: 135px;
  }

  .AlexLeraCircle {
    margin-top: 32px;
    margin-left: 8px;
  }

  .FacebookTitle {
    line-height: 15px;
    margin-top: 24px;
  }

  .FacebookText {
    margin-top: 16px;
  }
}
