.propertiesBGImage {
    height: 100vh;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    mask-image: linear-gradient(180deg, rgba(196, 196, 196, 1) 0%, rgba(196, 196, 196, 0.5) 13.3%, #C4C4C4 21.61%, rgba(196, 196, 196, 0.75) 70.35%, rgba(196, 196, 196, 0) 100%);
    mask-mode: alpha;
}

.propertyMainCompanyName {
    margin: 0;
    padding-top: 35vh;
    font-size: 3vw;
    font-family: 'Cormorant Garamond', serif;
    font-weight: 500;
    opacity: 75%;
    /* font-size: 96px; */
}

.propertyMainSecondaryLine{
    margin: 0;
    font-weight: 700;
    font-family: 'Cormorant Garamond', serif;
    font-size: 5vw;
    /* opacity: 90%; */
}

.propertiesOverlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    /* margin-top: -80px; */
    background-color: rgba(0,0,0,0.6);
    z-index: 0;
  }

 .MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.propertiesButtonFilter.css-wk2qm8-MuiButtonBase-root-MuiButton-root {
     background-color: rgba(255,255,255,0.3);
     border-radius: 5px;
     font-weight: 300;
     /* color: rgba(41, 40, 45, 1); */
     color: #F1EDE9;
     /* color: rgba(255, 255, 255, 1); */
     border: solid 1px rgba(255,255,255, 0.25);
     font-size: 20px;
     margin-top: 40px;
     padding: 8px;
     padding-left: 32px;
     padding-right: 32px;
     box-shadow: 0px 0px 4px 2px rgba(255, 255, 255, 0.2);
     backdrop-filter: blur(2px);
 }

 .MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.propertiesButtonFilter.css-wk2qm8-MuiButtonBase-root-MuiButton-root:hover {
    background-color: rgba(255,255,255,0.4);
    /* color: black; */
 }

 .propertyCardContainer {
    margin: auto;
    width: 80vw;
    display: inline-block;
    align-items: flex-start;
}

.filterTopHeader {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    margin: 0;
    margin-top: 16px;
}

.css-1z7n62{
    visibility: visible !important;
}

.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.propertyFilterButton.css-wk2qm8-MuiButtonBase-root-MuiButton-root {
    /* background-color: rgba(228, 203, 239, 0.1); */
    padding: 16px 32px 16px 32px; 
    border: solid 1px rgba(0, 0, 0, 0.1)
}

.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.propertyFilterButton.css-wk2qm8-MuiButtonBase-root-MuiButton-root:hover {
    background-color: rgba(0,0,0,.1);
    border: solid 1px rgba(0, 0, 0, 0.3)
}

.propertyResetBtn {
    text-align: center;
    display: block;
    margin: -16px 0 16px 0;
    color: black;
    cursor: pointer;
    text-decoration: underline; 
}

@media only screen and (max-width: 960px) {
    
    .propertyCardContainer {
        margin: auto;
    }

    .MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.propertiesButtonFilter.css-wk2qm8-MuiButtonBase-root-MuiButton-root {
        margin-top: 120px;
        font-size: 24px;
        color: #F1EDE9;
    }
    .propertyMainCompanyName{
        font-size: 7vw;
    }

    .propertyMainSecondaryLine{
        font-size: 10vw;
    }
 }