.ccard-main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.ccard {
  /* display: inline-block; */
  display: flex;
  width: 300px;
  height: 100px;
  background: rgba(98, 92, 96, 0.7);
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  will-change: transform;
  transition: box-shadow 0.5s;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: absolute;
  align-items: center;
  justify-content: center;
}

.ReasonToBuy {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  z-index: 3;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: absolute;
  align-items: center;
  justify-content: center;
}

/* .ReasonToBuy p {
  margin-top: 26px;
  margin-bottom: 26px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
} */
.ccard p {
  margin-top: 26px;
  margin-bottom: 26px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
}
/* .ccard p {
  height: 100px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  z-index: 11;
} */
