.ApprovalTextField {
    width: 51%;
}

.approvalHeader {
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 0px;
    margin-top: 40px;
}

.GetPreApprovalMenuItem {
    display: block !important;
    padding: 6px 16px 6px 16px !important;
    font-size: 1rem !important;
}

.GetPreApprovalSelectMain {
    width: 51%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4px;
}

@media only screen and (max-width: 898px) {

    .GetPreApprovalSelectMain {
        width: 80%;
    }

    .ApprovalTextField {
        width: 80%;
    }

    .approvalHeader {
        font-size: 8vw;
        min-width: 300px;
        max-width: 80%;
        margin: auto;
        margin-top: 40px;
    }
    
}

@media only screen and (max-width: 500px) {


    .approvalHeader {
        font-size: 9vw;
    }
}