.swiper {
    width: 100%;
    height: 100%;
  }

.mySwiper {
    height: 500px;
    min-width: 900px;
    max-width: 1000px;
}

.mySwiper2 {
  /* height: 600px; */
  min-width: 900px;
  max-width: 1000px;
}

.swiper-pagination-bullet-active {
    background-color: #000 !important;
    /* background-color: #6868AC !important; */
}

/* .swiper-pagination-bullet {
  background-color: #6868AC !important;
} */

.swiper-button-next,
.swiper-button-prev {
    /* background-color: white; */
    /* background-color: rgba(255, 255, 255, 0.5); */
    right:10px;
    padding: 30px;
    color: #6868AC !important;
}
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 898px) {
    .mySwiper {
      height: 100%;
      min-width: 95vw;
      max-width: 95vw;
    }

    .swiper-button-next,
    .swiper-button-prev,
    .swiper-rtl {
      margin-top: 10% !important;
      
  }

  .swiper-button-next {
    right: -20px !important;
  }
  .swiper-button-prev {
    left: -20px !important;
  }

  .swiper-pagination {
    bottom: 100px;
  }

  .swiper-pagination-bullet-active {
    /* background-color: #000 !important; */
    background-color: #6868AC !important;
}

.swiper-pagination-bullet {
  background-color: #6868AC !important;
}
}