.FreeConsultationImg {
    background-position: center;
    height: 105vh;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 40px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.FreeConsultationHeader1 {
    z-index: 3;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: absolute;
    align-items: center;
    justify-content: center;
    font-size: 92px;
    font-weight: 300;
    margin-top: 40vh;
}

.FreeConsultationHeader2{
    z-index: 3;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: absolute;
    align-items: center;
    justify-content: center;
    font-size: 156px;
    font-weight: 600;
    margin-top: 50vh;
    color: #FF6B00;
}

/* .FreeConsultationButton {
    background: rgba(241, 237, 233, 0.75);
    box-shadow: 2px 2px 4px 2px rgba(241, 237, 233, 0.25);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    width: 400px;
    height: 105px;

    z-index: 3;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: absolute;
    align-items: center;
    justify-content: center;
    margin-top: 55vh
} */

.FreeConsultationButton{
    text-transform: none;
    color: #29282D !important;
    background: rgba(241, 237, 233, 0.75) !important;
    box-shadow: 0px 0px 4px 2px rgba(241, 237, 233, 0.25) !important;
    z-index: 3;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    top: 75vh;
    border-radius: 20px !important;
    text-align: center;
    position: absolute;
    align-items: center;
    justify-content: center;
}

.FreeConsultationButton:hover {
    background: rgba(241, 237, 233, 0.9) !important;
    box-shadow: 0px 0px 4px 2px rgba(241, 237, 233, 0.5) !important;
}

.FreeConsultationText{
    font-size: 32px;
    padding: 8px 16px 8px 16px;
    margin: 10px;
}

@media only screen and (max-width: 898px) {

    .FreeConsultationImg{
        background-position-x: 30%;
    }

    .FreeConsultationHeader1{
        font-size: 64px;
        margin-top: 45vh;
    }

    .FreeConsultationHeader2{
        font-size: 92px;
        margin-top: 53vh;
    }

    .FreeConsultationButton {
        top: 70vh;
    }

}